import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Breadcrumb, Card, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from "react-redux";
import { getOfferById } from "../../store/actions/offer";
import { selectActiveCompanyName } from "../../store/reducers/userCompany";

export default function ViewOffer() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const companyName = useSelector(selectActiveCompanyName);
  const offers = useSelector((state) => state.offer?.offer?.offer) || {};

  useEffect(() => {
    if (id) {
      dispatch(getOfferById(id));
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <div className=" py-4">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div>
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/offers">{t("offers")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("view")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("offers")}</h4>
          <p>{t("View your Offers")}</p>
        </div>
      </div>
      <Card>
        <Card.Header>
          <div className="row">
            <div className="col-md-8">
              <h5>{t("Offer Details")}</h5>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Table bordered hover responsive="sm">
            <tbody>
              <tr>
                <th>{t("Company")}</th>
                <td>{companyName}</td>
              </tr>

              <tr>
                <th>{t("Address")}</th>
                <td>
                  {` ${offers.company_street ? offers.company_street : ""}
                    ${offers.company_house_number
                      ? offers.company_house_number
                      : ""
                    }
                    ${offers.company_house_number_suffix
                      ? offers.company_house_number_suffix
                      : ""
                    }
                    `}
                  <br />
                  {`${offers.company_postcode ? offers.company_postcode : ""}
                    ${offers.company_city ? offers.company_city : ""}
                    ${offers.company_country ? offers.company_country : ""}`}
                </td>
              </tr>

              <tr>
                <th>{t("Phone Number")}</th>
                <td>{offers.company_phone_number}</td>
              </tr>
              <tr>
                <th>{t("Offer Date")}</th>
                <td>
                  {moment
                    .tz(offers.offer_date, "Europe/Amsterdam")
                    .format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr>
                <th>{t("Offer Expiration Date")}</th>
                <td>
                  {moment
                    .tz(offers.expiration_date, "Europe/Amsterdam")
                    .format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr>
                <th>{t("Title")}</th>
                <td>{offers.offer_title}</td>
              </tr>
              <tr>
                <th>{t("Offer Notes")}</th>
                <td>{ReactHtmlParser(offers.offer_notes)}</td>
              </tr>
              <tr>
                <th>{t("Purchase conditions")}</th>
                <td>{ReactHtmlParser(offers.offer_purchase_conditions)}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Card className="mt-5">
        <Card.Header>
          <div className="row">
            <div className="col-md-8">
              <h5>{t("Customer Details")}</h5>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Table bordered hover responsive="sm">
            <tbody>
              <tr>
                <th>{t("Name")}</th>
                <td>
                  <b>{offers.customer_name}</b>
                </td>
              </tr>
              <tr>
                <th>{t("Address")}</th>
                <td>
                  {`${offers.customer_street ? offers.customer_street : ""}
                    ${offers.customer_house_number
                      ? offers.customer_house_number
                      : ""
                    }
                    ${offers.customer_house_number_suffix
                      ? offers.customer_house_number_suffix
                      : ""
                    }
                    `}{" "}
                  <br />
                  {`${offers.customer_postcode ? offers.customer_postcode : ""}
                    ${offers.customer_city ? offers.customer_city : ""}
                    ${offers.customer_country ? offers.customer_country : ""}`}
                </td>
              </tr>
              <tr>
                <th>{t("Phone Number")}</th>
                <td>
                  {offers.customer_phone_number}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Header>
          <div className="row">
            <div className="col-md-8">
              <h5>{t("Contact Person Details")}</h5>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Table bordered hover responsive="sm">
            <tbody>
              <tr>
                <th>{t("Name")}</th>
                <td>
                  <b>{offers.contact_person_name}</b>
                </td>
              </tr>
              <tr>
                <th>{t("Email")}</th>
                <td>{offers.contact_person_email}</td>
              </tr>
              <tr>
                <th>{t("Phone Number")}</th>
                <td>{offers.contact_person_phone_number}</td>
              </tr>
              <tr>
                <th>{t("Sent by email")}</th>
                <td>{offers.email_to ? `Yes (${offers.email_to})` : "No"}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}
