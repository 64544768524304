import React, { useEffect, useState } from "react";
import { Button, Form, Image, Modal, Table } from "react-bootstrap";
import "../styles.css";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { numberFormatter } from "../../libs/numberFormatter";
import { useSelector } from "react-redux";
import { selectCompanySettings } from "../../store/reducers/settings";
import { selectActiveCompany } from "../../store/reducers/userCompany";
import { useFormikContext } from "formik";
import Invoice from "../libs/Invoice";
import { selectTemplates } from "../../store/reducers/templates";
import { selectCustomers } from "../../store/reducers/customers";

function InvoiceLayout({
  show,
  handleClose,
  invoiceData,
  invoiceLines,
  getInvoiceData,
}) {
  const { t } = useTranslation();
  const companySettings = useSelector(selectCompanySettings);
  const company = useSelector(selectActiveCompany);
  const customers = useSelector(selectCustomers);

  const [template, setTemplate] = useState();
  const [customer, setCustomer] = useState();

  const templates = useSelector(selectTemplates);
  const { values, errors, setFieldValue } = useFormikContext();

  const backgroundImg = template?.background_image_url || "";

  useEffect(() => {
    let selectedTemplate = templates?.filter((template) => template?.id === values.template_id)?.[0];

    // if there is no template found for the template id, use default
    if (!selectedTemplate) {
      selectedTemplate = templates?.filter((template) => template?.is_default === true)?.[0];

      if (selectedTemplate?.id) {
        setFieldValue('template_id', selectedTemplate?.id)
      }
    }

    setTemplate(selectedTemplate);
  }, [templates, values.template_id]);

  useEffect(() => {
    let selectedCustomer = customers?.filter((customer) => customer?.id === values.customer_id)?.[0];

    setCustomer(selectedCustomer);
  }, [customers, values.customer_id]);

  const [totalPrices, setTotalPrices] = useState({
    totalPriceExclVat: 0,
    totalVat: 0,
    totalInclVat: 0,
  });

  useEffect(() => {
    setTotalPrices(Invoice.calculateTotalPrices(values.invoice_lines));
  }, [values.invoice_lines]);

  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    if (show) {
      let invoiceData = getInvoiceData(values)
      invoiceData.action = 'generate-preview'

      Invoice.storeInvoice(invoiceData)
        .then(res => setHtmlContent(res))
    }
  }, [show]);



  return (
    <Modal show={show} onHide={handleClose} size="lg">

      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />

      <Modal.Footer>
        <Button variant="primary" onClick={() => handleClose()}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InvoiceLayout;
