import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Auth from "../../auth/libs/Auth";
import AlertDismissible from "../../components/old/AlertDismissible";
import { numberFormatter } from "../../libs/numberFormatter";
import PriceCard from "../../components/PriceCard";
import StatusCard from "../../components/StatusCard";
import TemplateService from "../../settings/components/Template/TemplateService";
import { getCustomerById } from "../../store/actions/customers";
import { startLoading, stopLoading } from "../../store/actions/loading";
import { getOfferById } from "../../store/actions/offer";
import { offerSchema } from "../../validation";
import OffersActions from "../libs/Offers";
import OfferForm from "./OfferForm";
import OfferInfo from "./OfferInfo";
import OfferLayout from "./OfferLayout";
import "../style/loader.scss";
import { selectTemplates } from "../../store/reducers/templates";
import { selectCustomer } from "../../store/reducers/customers";
import Preloader from "../../admin/layout/components/Preloader";
import { activeCompanyFetched, selectActiveCompany } from "../../store/reducers/userCompany";

export default function CreateOffer() {
  const todayDate = new Date();
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const newDate = todayDate.setDate(todayDate.getDate() + 29);
  const activeCompany = useSelector(selectActiveCompany);
  const offerById = useSelector((state) => state.offer?.offer?.offer) || {};

  const [offerDate, setOfferDate] = useState(newDate);
  const [customerId, setCustomerId] = useState("");
  const [status, setStatus] = useState(t("open"));
  const [TemplateId, setTemplateId] = useState("");
  const [message, setMessage] = useState({});
  const [error, setError] = useState([]);
  const [offerModel, setOfferModel] = useState(false);
  const [publishOfferChecked, setPublishOfferChecked] = useState(true);
  const [isChecked, setIsChecked] = useState(true);
  const [template, setTemplate] = useState(null);
  const [formattedTotalPriceExclVat, setFormattedTotalPriceExclVat] =
    useState("");
  const [formattedTotalVat, setFormattedTotalVat] = useState("");
  const [formattedTotalInclVat, setFormattedTotalInclVat] = useState("");

  useEffect(() => {
    Auth.getUserData()
      .then((res) => {
        dispatch(stopLoading());
        if (res?.companies?.length > 0) {
          dispatch(activeCompanyFetched(res.companies[0]));
        }
      })
      .catch();
  }, []);
  const offerexpirationDate = moment
    .tz(offerDate, "Europe/Amsterdam")
    .format("YYYY-MM-DD");
  const today = moment.tz(new Date(), "Europe/Amsterdam").format("YYYY-MM-DD");
  const customer = useSelector(selectCustomer);
  const templates = useSelector(selectTemplates);

  const setAndReturnFormattedPrice = (offer_line, calculationType) => {
    let price = [];
    let totalVat = 0;
    if (offer_line) {
      for (let i = 0; i < offer_line?.length; i++) {
        price.push(offer_line[i]?.amountExclVat);
        totalVat =
          totalVat +
          (offer_line[i]?.amountExclVat / 100) * offer_line[i]?.vat_percentage;
      }
    }
    let amountExclVat = price.reduce((sum, newValue) => sum + newValue, 0);
    setTimeout(() => {
      setFormattedTotalPriceExclVat(amountExclVat);
      setFormattedTotalVat(totalVat);
      setFormattedTotalInclVat(amountExclVat + totalVat);
    }, 0);

    if (calculationType === 1) {
      return formattedTotalPriceExclVat;
    } else if (calculationType === 2) {
      return formattedTotalVat;
    } else if (calculationType === 3) {
      return formattedTotalInclVat;
    }
  };

  const handelOfferModel = () => {
    setOfferModel((prev) => !prev);
  };

  const handleSendEmail = () => {
    setIsChecked((prev) => !prev);
  };

  const handlePublishOffer = () => {
    setPublishOfferChecked((prev) => !prev);
    setStatus(t("draft"));
  };

  const storeOffers = (values) => {

    if (id) {
      dispatch(startLoading());
      OffersActions.editOffer(id, values)
        .then((res) => {
          if (res.success === true) {
            history.push("/offers");
            dispatch(stopLoading());
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(stopLoading());
        });
    } else {
      dispatch(startLoading());
      OffersActions.storeOffer(values)
        .then((res) => {
          dispatch(stopLoading());
          history.push("/offers");
        })
        .catch((error) => {
          dispatch(stopLoading());
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getOfferById(id));
    }
    dispatch(getCustomerById(customerId));
    // eslint-disable-next-line
  }, [id, customerId]);

  const offerLines = offerById?.offer_lines || [
    {
      description: "",
      product_id: "",
      quantity: 1,
      price: 0,
      price_formatted: 0,
      vat_percentage: 0,
      amountExclVat_field: 0,
    },
  ];

  const offerLineData = offerLines?.map((offerLine) => ({
    amountExclVat: offerLine.price / 100,
    description: offerLine.description,
    price: offerLine.price,
    finalPrice: numberFormatter.format(offerLine.price / 100),
    product_id: offerLine.product_id,
    quantity: offerLine.quantity,
    vat_percentage: offerLine.vat_percentage,
  }));

  useEffect(() => {
    if (TemplateId) {
      TemplateService.getTemplate(TemplateId)
        .then((data) => {
          setTemplate(data.template);
        })
        .catch((error) => {
          console.log("error ", error);
        });
    }
  }, [TemplateId]);

  useEffect(() => {
    if (templates) {
      let template = templates.filter((item) => item.is_default === 1);
      if (template.length) {
        setTemplate(template[0]);
      }
    }
    // eslint-disable-next-line
  }, []);

  const initialValues = {
    customer_id: offerById?.customer_id,
    contact_person_id: offerById?.contact_person_id,
    template_id: TemplateId,
    offer_title: offerById?.offer_title,
    offer_notes: offerById?.offer_notes,
    offer_purchase_conditions: offerById?.offer_purchase_conditions,
    email_to: offerById?.email_to,
    expiration_date: id ? offerById?.expiration_date : offerexpirationDate,
    status: status,
    company_id: activeCompany?.id,
    offer_line: offerLineData,
  };

  return (
    <>
      {!activeCompany?.id ? (
        <Preloader />
      ) : null}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/offers">{t("offers")}</Breadcrumb.Item>
            <Breadcrumb.Item active>
              {id ? t("edit") : t("Create")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <h4>{id ? t("Edit offer") : t("Create new offer")}</h4>
          <p className="mb-0">
            {id
              ? t("Edit an existing offer")
              : t("Create an Offer for your customer")}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {message.show ? (
            <AlertDismissible
              textMessage={message.message}
              variant={message.variant}
            />
          ) : (
            ""
          )}
          {error &&
            error.map((message, index) => {
              return (
                <AlertDismissible
                  key={index}
                  textMessage={message.message}
                  variant="danger"
                />
              );
            })}
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          values['company_id'] = activeCompany?.id;
          setTimeout(() => {
            setSubmitting(false);
            storeOffers(values);
          }, 1000);
        }}
        validationSchema={offerSchema}
      >
        {({
          setFieldValue,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          handleBlur,
          values,
          handleChange,
        }) => (

          < Form onSubmit={handleSubmit}>

            <OfferInfo
              offerDate={offerDate}
              setOfferDate={setOfferDate}
              customerId={customerId}
              setCustomerId={setCustomerId}
              setMessage={setMessage}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              newDate={newDate}
              offerById={offerById}
              values={values}
              setTemplateId={setTemplateId}
            />

            <OfferForm
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              values={values}
              handleBlur={handleBlur}
            />

            <OfferLayout
              show={offerModel}
              handleClose={handelOfferModel}
              values={values}
              Template={template}
              offerexpirationDate={offerexpirationDate}
              today={today}
              totalPriceExcl={formattedTotalPriceExclVat}
              totalBtwPrice={formattedTotalVat}
              totalPriceIncl={formattedTotalInclVat}
              customerData={customer}
            />

            <PriceCard
              productLineValues={values.offer_line}
              setAndReturnFormattedPrice={setAndReturnFormattedPrice}
              onClick={() => handelOfferModel()}
              totalPriceExclVat={formattedTotalPriceExclVat}
              totalVat={formattedTotalVat}
              totalInclVat={formattedTotalInclVat}
            />

            <StatusCard
              publishChecked={publishOfferChecked}
              handlePublish={handlePublishOffer}
              isChecked={isChecked}
              isSubmitting={isSubmitting}
              values={values}
              setFieldValue={setFieldValue}
              handleSendEmail={handleSendEmail}
              text={"Publish offer"}
            />
          </Form>
        )}
      </Formik >
    </>
  );
}
