// App.js
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Admin } from "./admin";
import Auth from "./auth/libs/Auth";
import { loggedInRoutes, guestRoutes, resellerRoutes } from "./routes";
import Subscriptions from "./subscription/components/Subscriptions";
import Tracking from "./tracking/Tracking";
import { useDispatch, useSelector } from "react-redux";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ResellerAuth from "./reseller/libs/ResellerAuth";
import ResellerLayout from "./reseller/ResellerLayout";
import { fetchUser } from "./store/actions/userCompany";
import { selectUserSubscription, selectUserType } from "./store/reducers/userCompany";
import PasswordProtection from "./components/PasswordProtection"; // Import the new component

const AppContent = () => {
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);
  const hasSubscription = useSelector(selectUserSubscription);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Tracking/>
      <Switch>
        {ResellerAuth.isResellerLoggedIn() && userType === "reseller" && (
          <ResellerLayout>
            {resellerRoutes.map((route, index) => (
              <Route
                key={route.name}
                path={route.path}
                exact={route.exact}
                render={route.component}
              />
            ))}
          </ResellerLayout>
        )}

        {Auth.isLoggedIn() && userType === "user" &&
          (hasSubscription === false ? (
            <Subscriptions/>
          ) : (
            <Admin>
              {loggedInRoutes.map((route, index) => (
                <Route
                  key={route.name}
                  path={route.path}
                  exact={route.exact}
                  render={route.component}
                />
              ))}
            </Admin>
          ))}

        {!Auth.isLoggedIn() &&
          guestRoutes.map((route, index) => (
            <Route
              key={route.name}
              path={route.path}
              render={route.component}
              exact={route.exact}
            />
          ))}
      </Switch>
    </React.Fragment>
  );
};

// Wrap the entire app with password protection
const App = () => (
  <PasswordProtection>
    <AppContent />
  </PasswordProtection>
);

export default App;