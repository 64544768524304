import React from "react";
import { Button, ButtonGroup, Dropdown } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEllipsisH, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { numberFormatter } from "../../libs/numberFormatter";
import { useTranslation } from "react-i18next";


export default function ProductTableRow(props) {
    const { t } = useTranslation();

    const vatFormatter = (vatValue) => {
        let finalVat = "";
        switch (vatValue) {
            case 0:
                finalVat = t("VAT 0%");
                break;

            case 1:
                finalVat = t("VAT shifted 0%");
                break;

            case 2:
                finalVat = t("VAT exempt 0%");
                break;

            case 6:
                finalVat = t("VAT low 6%");
                break;

            case 9:
                finalVat = t("VAT low 9%");
                break;

            case 21:
                finalVat = t("VAT high 21%");
                break;

            default:
                finalVat = vatValue;
        }
        return finalVat;
    }


    return (
        <tr>
            <td>
                <span className="fw-normal">
                    {props.index + 1}
                </span>
            </td>
            <td>
                <span className="fw-normal">
                    {props.name}
                </span>
            </td>
            <td>
                <span className="fw-normal">
                    {props.product_code}
                </span>
            </td>
            <td>
                <span className="fw-normal">
                    {props.ean}
                </span>
            </td>
            <td>
                <span className="fw-normal">
                    {numberFormatter.format(props.price / 100)}
                </span>
            </td>
            <td>
                <span className="fw-normal">
                    {vatFormatter(props.vat)}
                </span>
            </td>
            <td>
                <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle as={Button} split variant="link" drop="down" className="text-dark m-0 p-0"
                        id="dropdown-split-basic">
                        <span className="icon icon-sm">
                            <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="user-dropdown">
                        <>
                            <Dropdown.Item href={`/edit-product/${props.id}`}>
                                <FontAwesomeIcon icon={faEdit} className="me-2" /> {t("edit")}
                            </Dropdown.Item>
                            <Dropdown.Item className="text-danger"
                                onClick={(e) => {
                                    if (
                                        window.confirm(
                                            t("delete product")
                                        )
                                    ) {
                                        props.deleteProduct(props.id);
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> {t("remove")}
                            </Dropdown.Item>
                        </>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    );
}
