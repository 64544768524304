import React, { useEffect, useState } from "react";
import { Alert, Button, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import OffersActions from "../libs/Offers";
import moment from "moment-timezone";
import CustomSpinner from "../../components/CustomSpinner";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from 'react-html-parser';
import { numberFormatter } from "../../libs/numberFormatter";
import { useSelector } from "react-redux";
import "../style/print.scss";

export default function OfferDetails() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { uuid } = useParams();
  const [offers, setOffers] = useState([]);
  const [expiredMessage, setExpiredMessage] = useState();
  const [acceptedOffer, setAcceptedOffer] = useState("");
  const [companyName, setCompanyName] = useState("")
  const logo = useSelector(state => state.settings?.companySettings?.companySetting?.logo_url);

  const backgroundImg = process.env.REACT_APP_API_URL + offers.template?.background_image || "";

  useEffect(() => {
    OffersActions.getOfferByUUID(uuid)
      .then((res) => {
        setLoading(false);
        if (res.message) {
          setExpiredMessage(res.message);
        }
        setOffers(res);
        setCompanyName(res.company.name)
      })
      .catch((error) => console.log(error));
  }, [uuid]);

  const acceptOffer = () => {
    OffersActions.acceptOffer(uuid)
      .then((res) => {
        setLoading(false);
        setAcceptedOffer(res.message);
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      {acceptedOffer && <Alert variant="success" className="no-print">{acceptedOffer}</Alert>}
      <>
        {loading ? (
          <CustomSpinner />
        ) : expiredMessage ? (
          <Alert variant="danger">
            <p>{expiredMessage}</p>
          </Alert>
        ) : (
          <div className="d-flex flex-column container"
            style={{
              backgroundImage: `url(${backgroundImg})`,
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="fw-bold fs-2 d-flex justify-content-center">
              {offers.template?.show_logo === 1 && (
                <img src={`${logo}`} width="100" height="100" alt="logo" />
              )}
            </div>
            <div className="d-flex justify-content-between">
              <div className="mt-3">
                <h1 className="mt-2">{companyName}</h1>
                <h4>
                  {` ${offers.company_street ? offers.company_street : ""}
                                        ${offers.company_house_number ? offers.company_house_number : ""}
                                        ${offers.company_house_number_suffix ? offers.company_house_number_suffix : ""}
                                    `}
                  <br />
                  {`${offers.company_postcode ? offers.company_postcode : ""}
                                        ${offers.company_city ? offers.company_city : ""}
                                        ${offers.company_country ? offers.company_country : ""}`}
                </h4>
                <h4>{offers.company_email}</h4>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center mt-3">
              <h2>{t("Offer details")}</h2>
            </div>
            <div className=" mt-2 d-flex justify-content-between">
              <div className="col-md-6">
                <strong>{t("Offered to")}</strong>
                <hr style={{ margin: "0px 0px 5px 0px", opacity: 0.5 }} />
                {offers && (
                  <div style={{ fontSize: "14px" }}>
                    <div>{offers.customer_name}</div>
                    <div>
                      {`${offers.customer_street ? offers.customer_street : ""}
                                                ${offers.customer_house_number
                          ? offers.customer_house_number
                          : ""
                        }
                                                    ${offers.customer_house_number_suffix
                          ? offers.customer_house_number_suffix
                          : ""
                        }
                                            `}
                      <br />
                      {`${offers.customer_postcode ? offers.customer_postcode : ""
                        }
                                            ${offers.customer_city ? offers.customer_city : ""}
                                            ${offers.customer_country ? offers.customer_country : ""}`}
                    </div>
                    <div>{offers.customer_phone_number}</div>
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <div style={{ float: "right" }}>
                  <div>
                    <strong>{t("Offer date")}: </strong>
                    {moment
                      .tz(offers.offer_date, "Europe/Amsterdam")
                      .format("DD-MM-YYYY")}
                  </div>
                  <div>
                    <strong>{t("Offer Expiration Date")}: </strong>
                    {moment
                      .tz(offers.expiration_date, "Europe/Amsterdam")
                      .format("DD-MM-YYYY")}
                  </div>
                  <div>
                    <strong>{t("Offer Number")}:</strong> {offers.offer_number}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex mt-4 flex-column ">
              <h4>{t("Offer Notes")}</h4>
              <p className="fs-4">{ReactHtmlParser(offers.offer_notes)}</p>
            </div>

            <div className="d-flex col-12 mt-4 overflow-table">
              <Table size="sm">
                <thead>
                  <tr
                    style={{
                      fontSize: " 0.8em",
                      lineHeight: "0.8",
                      background: offers.template?.theme_color_primary || "#0948B3",
                      color: "#fff",
                    }}
                    className="table-header"
                  >
                    <th className="text-center">SL#</th>
                    <th className="text-center">{t("Description")}</th>
                    <th className="text-center">{t("Quantity")}</th>
                    <th className="text-center">{t("unit price")}</th>
                    <th className="text-center">{t("VAT Percentage")}</th>
                    <th className="text-center">{t("Total")}</th>
                  </tr>
                </thead>
                <tbody>
                  {offers.offer_lines &&
                    offers.offer_lines.map((offerLine, index) => {
                      return (
                        <tr key={offerLine.id}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{offerLine.description}</td>
                          <td className="text-center">{offerLine.quantity}</td>
                          <td className="text-center">
                            {numberFormatter.format(offerLine.price)}
                          </td>
                          <td className="text-center">
                            {offerLine.vat_percentage === 0 || offerLine.vat_percentage === 1 || offerLine.vat_percentage === 2
                              ? `0%`
                              : `${offerLine.vat_percentage}%`}
                          </td>
                          <td className="text-center">
                            {numberFormatter.format(
                              (offerLine.quantity * offerLine.price)
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div className="d-flex mt-4 flex-column">
              <h4>{t("Purchase Conditions")}</h4>
              <p className="fs-4">{ReactHtmlParser(offers.offer_purchase_conditions)}</p>
            </div>

            {offers.template?.closing_text !== null && (
              <div
                style={{
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "center",
                  justifyContent: "center",
                  background: offers.template?.theme_color_secondary || "#78ad91",
                  color: "#fff"
                }}
              >
                <p className="text-white mt-3">{offers.template?.closing_text}</p>
              </div>
            )}

            <div className="btn-wrapper my-5">
              <Button
                variant="success"
                className="mx-1 my-1"
                onClick={() => acceptOffer()}
                disabled={offers.status === 'accepted'}
              >
                {t("Accept Offer")}
              </Button>
              <Button
                variant="info"
                className="mx-1 my-1"
                onClick={() => window.print()}
              >
                {t("Print Offer")}
              </Button>
            </div>
          </div>
        )}
      </>
    </>
  );
}
