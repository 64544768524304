import * as yup from "yup";
import i18next from "i18next";

export const validationSchema = () => {
  return yup.object({
    company_name: yup.string().required("Please enter a company name"),
    phone_number: yup.string().trim().nullable(),
    city: yup.string().trim().required("Please enter a city"),
    street: yup.string().trim().required("Please enter a street"),
    house_number: yup.string().trim().required("Please enter a house number"),
    postcode: yup.string().trim().required("Please enter postcode"),
  });
};

export const partialPaymentSchema = () => {
  return yup.object().shape({
    paid_amount: yup
      .number()
      .required("Please enter paid amount")
      .test(
        'higher',
        i18next.t('The paid amount should never be higher than the total invoice amount'),
        function (value) {
          const { total_amount } = this.parent;
          return total_amount > value;
        }
      )
    ,
  });
};

export const SelectCompanySchema = () => {
  return yup.object().shape({
    select_company: yup.string().required("Please select company"),
  });
};

export const companySettingsSchema = () => {
  return yup.object({
    logo_url: yup
      .mixed()
      .test("fileType", "Only image files are allowed", (value) => {
        return value
          ? ["image/jpeg", "image/png", "image/gif"].includes(value.type)
          : true;
      }),
    bank_account: yup
      .string()
      .required("Please enter a bank account")
      .nullable(),
    bank_account_holder: yup
      .string()
      .required("Please enter a bank account holder")
      .nullable(),
  });
};

export const invoiceInfoSchema = () => {
  return yup.object().shape({
    customer_id: yup.string().required("Please select a customer").trim(),
    contact_person_id: yup.string().nullable(true).trim(),
    invoice_date: yup
      .date()
      .nullable()
      .default(undefined)
      .transform((curr, orig) => (orig === "" ? null : curr))
      .typeError("Invalid Date")
      .required("Date Required"),
    template_id: yup.string().nullable().trim(),
    description: yup.string().required("Description required").trim(),
    invoice_lines: yup.array().of(
      yup.object().shape({
        product: yup
          .object()
          .label('Product')
          .shape({
            label: yup.string().required("Name required").typeError("Name is required").trim()
          }),
        quantity: yup
          .number()
          .min(1, "The minimum value for quantity must be 1")
          .required("Quantity required")
          .typeError("Quantity must be a number"),
        price: yup
          .number()
          .label("Price")
          .required("Please enter price")
          .typeError("Price must be a number"),
        vat_percentage: yup
          .number()
          .required()
          .typeError("VAT percentage must be a number"),
        amountExclVat_field: yup.string().required().trim(),
      })
    ),
  });
};

export const editInvoiceSchemaNew = () => {
  return yup.object().shape({
    customer_id: yup.string().required("Please select a customer").trim(),
    contact_person_id: yup.string().nullable(true).trim(),
    invoice_date: yup
      .date()
      .nullable()
      .default(undefined)
      .transform((curr, orig) => (orig === "" ? null : curr))
      // .typeError("Invalid Date")
      .required("Date Required"),
    template_id: yup.string().nullable(true).trim(),
    description: yup.string().required("Description required").trim(),
    invoice_lines: yup.array().of(
      yup.object().shape({
        product: yup
          .object()
          .label('Product')
          .shape({
            label: yup.string().required("Name required").typeError("Name is required").trim()
          }),
        quantity: yup
          .number()
          .min(1, "The minimum value for quantity must be 1")
          .required("Quantity required")
          .typeError("Quantity must be a number"),
        price: yup.string().required("Price required").trim(),
        vat_percentage: yup
          .number()
          .required()
          .typeError("VAT percentage must be a number"),
        amountExclVat_field: yup.string().required().trim(),
      })
    ),
  });
};

export const SettingsSchema = () => {
  return yup.object({
    expiration_date: yup
      .number()
      .positive()
      .label("Expiration Date")
      .required("Please enter an expiration date")
      .nullable()
      .typeError("Expiration date must be a number")
      .min(1, "Expiration Date must be greater than or equal to 1"),
    invoice_prefix: yup
      .string()
      .trim()
      .nullable()
      .required("Please enter an invoice prefix"),
    next_invoice_number: yup
      .number()
      .positive()
      .nullable()
      .typeError("Next invoice number must be a number")
      .label("Next invoice number")
      .required("Please enter a next invoice number")
      .min(1, "Next invoice number must be greater than or equal to 1"),
    reminder_interval: yup
      .number()
      .label("Reminder days")
      .typeError("Reminder days must be a number")
      .required("Please enter a Reminder days")
      .min(1, "Reminder days must be greater than or equal to 1"),
    bcc_email: yup
      .string()
      .email("Please enter a valid email")
      .nullable(),
  });
};
const phoneValidation =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const firstInvoiceStep3Schema = () => {
  return yup.object({
    invoiceDescription: yup
      .string()
      .trim()
      .required("Please enter an invoice description"),
    bankAccount: yup
      .string()
      .required("Please enter a bank Account"),
    bankAccountholder: yup
      .string()
      .trim()
      .required("Please enter a bank account holder"),
    expirationDays: yup
      .number()
      .positive()
      .typeError("Expiration days must be a number")
      .label("Expiration days")
      .required("Please enter expiration days")
      .min(1),
    invoiceNumber: yup
      .mixed()
      .required("Please enter invoice number")
      .typeError("Invoice number must be a number"),
  });
};

export const schema = yup.object().shape({
  first_name: yup.string().required().trim().label("First Name"),
  last_name: yup.string().required().trim().label("Last Name"),
  prefix: yup.string().nullable().trim().label("Prefix"),
  email: yup
    .string()
    .email()
    .required("email is a required field")
    .trim()
    .label("Email"),
  password: yup
    .string()
    .required("password is a required field")
    .min(8)
    .trim()
    .label("Password"),
  confirm_password: yup
    .string()
    .required()
    .min(8)
    .oneOf([yup.ref("password")], "Passwords don't match")
    .trim()
    .label("Confirm Password"),
});

const dutchPostcodeValidation = /^[1-9][0-9]{3}[ ]?[A-Z]{2}$/;

export const firstInvoiceStep2Schema = () => {
  return yup.object({
    customerVAT: yup.string().trim(),
    customerCompany: yup
      .object()
      .required("Please enter a company name"),
    customerStreet: yup.string().trim().required("Please enter a street"),
    customerCity: yup.string().trim().required("Please enter a city"),
    customerHouseNumber: yup
      .number()
      .positive()
      .typeError("House number must be a number")
      .label("House number")
      .required("Please enter a house number")
      .min(1),
    customerPostcode: yup
      .string()
      .matches(dutchPostcodeValidation, "Postcode is not valid")
      .label("Postcode")
      .typeError("Postcode must be a number")
      .required("Please enter a postcode"),
    customerNumber: yup
      .string()
      .matches(phoneValidation, "Phone number is not valid"),
  });
};

export const offerSchema = () => {
  return yup.object({
    customer_id: yup.string().required("Please select customer"),
    contact_person_id: yup.string().required("Please select contact person"),
    template_id: yup.string().required("Please select template"),
    offer_title: yup.string().trim().required("Please enter title"),
    offer_notes: yup.string().trim().required("Please enter offer note"),
    offer_purchase_conditions: yup
      .string()
      .trim()
      .nullable()
      .required("Please enter purchase Conditions"),

    offer_line: yup.array().of(
      yup.object().shape({
        description: yup.string().required("Name required").trim(),
        quantity: yup
          .number()
          .min(1, "The minimum value for quantity must be 1")
          .typeError("Quantity must be a number")
          .required("Quantity required"),
        price: yup
          .number()
          .min(1, "The minimum value for price must be 1")
          .required("required")
          .typeError("Price must be a number"),
        vat_percentage: yup
          .number()
          .required()
          .typeError("VAT percentage must be a number"),
        amountExclVat: yup.string().required().trim(),
      })
    ),
  });
};
export const ResellerCompanySchema = () => {
  return yup.object({
    name: yup.string().trim().required("Please enter a company name"),
    street: yup.string().trim().required("Please enter a street"),
    house_number: yup.string().trim().required("Please enter a house number"),
    coc_number: yup.string().trim().required("Please enter a CoC Number"),
    postcode: yup.string().trim().required("Please enter a postCode"),
    city: yup.string().trim().required("Please enter a city"),
  });
};

export const FirstInvoiceStep4Schema = yup.object().shape({
  list: yup
    .array()
    .of(
      yup.object().shape({
        description: yup
          .string()
          .trim()
          .required("Please enter description")
          .nullable(),
        quantity: yup
          .number()
          .required("Please enter quantity")
          .min(1)
          .label("Quantity")
          .typeError("Quantity must be a number"),
        // price: yup.number().min(1).label("Price").required("Please enter price").typeError('Price must be a number'),
        price: yup
          .mixed()
          // .min(1)
          .label("Price")
          .required("Please enter price")
          .typeError("Price must be a number"),
        vat_percentage: yup
          .string()
          .trim()
          .required("Please enter VAT Percentage"),
      })
    )
    .min(1, "list is >= 1"),
});

export const contractSchema = () => {
  return yup.object({
    customer_id: yup.string().trim().required("Please enter customer"),
    start_date: yup.date().required("Please select date"),
    end_date: yup
      .date()
      .required("Please select date")
      .when("start_date", (startDate, schema) => {
        if (startDate) {
          const dayAfter = new Date(86400000);
          return schema.min(
            dayAfter,
            "End date has to be after than start date"
          );
        }
        return schema;
      }),

    first_invoice_date: yup.date().required("Please select date"),
    status: yup.string().trim().required("Please enter status"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please enter email"),
    frequency: yup.string().trim().required("Please enter frequency"),
    description: yup
      .string()
      .trim()
      .max(255, "Description too long")
      .nullable(),
  });
};

export const userSchema = () => {
  return yup.object({
    first_name: yup.string().trim().required("Please enter first name"),
    last_name: yup.string().trim().required("Please enter last name"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    password: yup
      .string()
      .required("Please enter a password")
      .min(8, "Password is too short - should be 8 chars minimum."),
    password_confirmation: yup
      .string()
      .required("Please enter a password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
};

export const updateUserSchema = () => {
  return yup.object({
    first_name: yup.string().trim().required("Please enter first name"),
    last_name: yup.string().trim().required("Please enter last name"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    password: yup
      .string()
      .required("Please enter a password")
      .min(8, "Password is too short - should be 8 chars minimum."),
    password_confirmation: yup
      .string()
      .required("Please enter a password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
};

export const resellerUserProfileSchema = () => {
  return yup.object({
    firstName: yup.string().trim().required("Please enter a first name"),
    lastName: yup.string().trim().required("Please enter a last name"),
    email: yup
      .string()
      .trim()
      .email("Please enter a valid email")
      .required("Email is required"),
  });
};

export const resellerCompanyProfileSchema = () => {
  return yup.object({
    companyName: yup.string().trim().required("Please enter a company name"),
    companyStreet: yup.string().trim().required("Please enter a street name"),
    companyEmail: yup
      .string()
      .trim()
      .email("Plesae enter a valid email")
      .required("Email is required"),
    city: yup.string().trim().required("Please enter a city"),
    companyHouseNumber: yup
      .number()
      .label("House Number")
      .required("Please enter a house number")
      .typeError("House Number must be a number"),
  });
};

export const userProfileSchema = () => {
  return yup.object({
    first_name: yup.string().trim().required("Please enter first name"),
    last_name: yup.string().trim().required("Please enter last name"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
  });
};

export const companyProfileSchema = () => {
  return yup.object({
    name: yup.string().trim().required("Please enter company name"),
    street: yup.string().trim().required("Please enter a street"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    postcode: yup.string().trim().required("Please enter a postcode"),
  });
};

export const productsSchema = () => {
  return yup.object({
    name: yup.string().trim().required("Please enter a product name"),
    price: yup
      .number()
      .positive()
      .label("Price")
      .typeError("Price must be a number")
      .required("Please enter a price")
      .min(1),
    vat: yup.number().required("Please select a Vat percentage"),
  });
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const contactPersonSchema = () => {
  return yup.object({
    first_name: yup.string().trim().min(3).required("Please enter first name"),
    last_name: yup.string().trim().min(3).required("Please enter last name"),
    email: yup
      .string()
      .trim()
      .email("Please enter a valid email")
      .required("Please enter an email"),
    phone_number: yup
      .string()
      .matches(phoneRegExp, "Please enter a valid phone number")
  });
};
