import React from "react";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { useField, useFormikContext } from "formik";

const AutoCompleteProductField = ({ name, products, setValue, value, ...props }) => {
  const { t } = useTranslation();

  const [field,] = useField(name);
  const { setFieldValue } = useFormikContext();

  const formatCreateLabel = (inputValue) => `${t('One-time')}: ${inputValue}`;

  return (
    <CreatableSelect
      name={name}
      menuPortalTarget={document.body}
      style={{ zIndex: 999, width: "100%" }}
      isClearable
      onChange={(value) => setFieldValue(name, value)}
      value={field.value}
      options={products?.map((product) => ({
        label: product.name,
        value: product.id,
        ...product
      }))}
      filterOption={(option, inputValue) => {

        inputValue = inputValue.toLowerCase()

        return (
          option.label.toLowerCase().includes(inputValue)
          || option.data.product_code?.toLowerCase().includes(inputValue)
          || option.data.ean?.toLowerCase().includes(inputValue)
        );
      }}
      placeholder={t("One time or existing product")}
      loadingMessage={t('Loading products...')}
      noOptionsMessage={() => t('No products found')}
      createOptionPosition="first"
      formatCreateLabel={formatCreateLabel}
    />
  );
};

export default AutoCompleteProductField;
