import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Form } from "@themesberg/react-bootstrap";
import Product from "../libs/Products";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { startLoading, stopLoading } from "../../store/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AlertDismissible from "../../components/old/AlertDismissible";
import { Field, Formik } from "formik";
import { productsSchema } from "../../validation";
import { formatCurrency } from "../../components/Currency";
import { selectActiveCompanyId } from "../../store/reducers/userCompany";
import { getErrorMessage } from "../../utils/helpers";

function CreateOrEditProduct() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [productCode, setProductCode] = useState(null);
  const [ean, setEan] = useState(null);
  const [price, setPrice] = useState("");
  const [Vat, setVAT] = useState("");
  const [message, setMessage] = useState({});
  const [errors, setErrors] = useState([]);

  const companyId = useSelector(selectActiveCompanyId);

  useEffect(() => {
    if (id) getProduct();
    //eslint-disable-next-line
  }, []);

  const currencyHandler = (e) => {
    let data = e.target.value.replace(/(€)/gm, "");
    let finalData = formatCurrency(data);
    setPrice(finalData);
  };

  const getProduct = () => {
    Product.getCompanyProduct(companyId, id)
      .then((data) => {
        if (data.success && data.product) {
          setName(data?.product?.name);
          let price = data.product.price ? data.product.price / 100 : "";
          let finalData = formatCurrency(price && price.toString());
          setProductCode(data.product.product_code);
          setEan(data.product.ean);
          setPrice(finalData);
          setVAT(data.product.vat ? data.product.vat : "");
        } else {
          setErrors([
            {
              show: true,
              message: t("There is something wrong. Product was not found"),
              variant: "danger",
            },
          ]);
        }
      })
      .catch((error) => {
        console.log("error ", error);
        setErrors([
          {
            show: true,
            message: t("There is something wrong. Product was not found"),
            variant: "danger",
          },
        ]);
      });
  };

  const storeProduct = (formValues) => {
    //format price
    const values = { ...formValues };
    values.price = parseInt(values.price);

    if (id) {
      dispatch(startLoading()); // show loading
      Product.updateCompanyProduct(companyId, values)
        .then((res) => {
          dispatch(stopLoading()); // hide loading
          if (res.success === true) {
            setMessage({
              show: true,
              message: res.message,
              variant: "success",
            });
            window.location.href = "/products";
          } else if (res.success === false) {
            setMessage({ show: true, message: res.message });
          }

          if (res.errors) {
            let array = [];
            for (const key in res.errors) {
              array.push({
                show: true,
                message: res.errors[key][0],
                variant: "danger",
              });
            }
            setErrors(array);
          }
        })
        .catch((error) => {
          dispatch(stopLoading());
          console.log(error);
        });
    } else {
      dispatch(startLoading()); // show loading
      Product.createCompanyProduct(companyId, values)
        .then((res) => {
          dispatch(stopLoading()); // hide loading
          if (res.success === true) {
            setMessage({
              show: true,
              message: res.message,
              variant: "success",
            });
            window.location.href = "/products";
          } else if (res.success === false) {
            setMessage({ show: true, message: res.message });
          }

          if (res.errors) {
            let array = [];
            for (const key in res.errors) {
              array.push({
                show: true,
                message: res.errors[key][0],
                variant: "danger",
              });
            }
            setErrors(array);
          }
        })
        .catch((error) => {
          setErrors([{ show: true, message: getErrorMessage(error), variant: "danger" }]);
          dispatch(stopLoading());
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/products">{t("products")}</Breadcrumb.Item>
            <Breadcrumb.Item active>
              {id ? t("edit") : t("Create")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <h4>{id ? t("Edit Product") : t("Create new Product")}</h4>
          <p className="mb-0">
            {id
              ? t("Edit product for your customer")
              : t("Create an product for your customer")}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {message.show ? (
            <AlertDismissible
              textMessage={message.message}
              variant={message.variant}
            />
          ) : (
            ""
          )}
          {errors &&
            errors.map((message, index) => {
              return (
                <AlertDismissible
                  key={index}
                  textMessage={message.message}
                  variant="danger"
                />
              );
            })}
        </div>
      </div>
      <Card className="mb-4">
        <Card.Header className="p-2">
          <h5>{t("Product Info")}</h5>
        </Card.Header>
        <Formik
          enableReinitialize
          initialValues={{
            id: id,
            name: name,
            product_code: productCode,
            ean: ean,
            price: price?.replace(/(€)/gm, "").replace(/(,)/gm, ""),
            vat: Vat,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              storeProduct(values);
            }, 1000);
          }}
          validationSchema={productsSchema}
        >
          {({
            setFieldValue,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            handleBlur,
            setFieldTouched
          }) => (
            <Form onSubmit={handleSubmit}>
              <Card.Body className="row">

                {/* product title */}
                <div className="col-md-12 mt-2">
                  <Form.Group className="col-md-6 mt-2">
                    <Form.Label>{t("Product Title")}</Form.Label>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          {t(
                            "This title is used as the default description when this product is used to create an invoice line"
                          )}
                        </Tooltip>
                      )}
                    >
                      <span className="p-1">
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </span>
                    </OverlayTrigger>

                    <Field
                      name="name"
                      value={name || ""}
                      onChange={(e) => {
                        setName(e.target.value);
                        setFieldValue("name", e.target.value);
                      }}
                      className="form-control"
                    />
                    {errors.name && touched.name ? (
                      <span className="error-message">{t(errors.name)}</span>
                    ) : null}
                  </Form.Group>
                </div>

                {/* product code */}
                <div className="col-md-12 mt-2">
                  <Form.Group className="col-md-6 mt-2">
                    <Form.Label>{t("Product Code")}</Form.Label>

                    <Field
                      name="product_code"
                      value={productCode || ""}
                      onChange={(e) => {
                        setProductCode(e.target.value);
                        setFieldValue("product_code", e.target.value);
                      }}
                      className="form-control"
                    />

                    {errors.product_code && touched.product_code ? (
                      <span className="error-message">{t(errors.product_code)}</span>
                    ) : null}

                  </Form.Group>
                </div>

                {/* ean */}
                <div className="col-md-12 mt-2">
                  <Form.Group className="col-md-6 mt-2">
                    <Form.Label>{t("EAN")}</Form.Label>

                    <Field
                      name="ean"
                      value={ean || ""}
                      onChange={(e) => {
                        setEan(e.target.value);
                        setFieldValue("ean", e.target.value);
                      }}
                      className="form-control"
                    />

                    {errors.ean && touched.ean ? (
                      <span className="error-message">{t(errors.ean)}</span>
                    ) : null}

                  </Form.Group>
                </div>

                {/* price */}
                <div className="col-md-12 mt-2">
                  <Form.Group className="col-md-6 mt-2">
                    <Form.Label>{t("price")}</Form.Label>
                    <Field
                      name="price"
                      type="text"
                      value={price}
                      onBlur={(e) => {
                        handleBlur(e);
                        currencyHandler(e);
                      }}
                      onChange={(e) => {
                        setPrice(e.target.value);
                        setFieldValue("price", e.target.value);
                      }}
                      className="form-control"
                    />
                    {errors.price && touched.price ? (
                      <span className="error-message">{t(errors.price)}</span>
                    ) : null}
                  </Form.Group>
                </div>

                {/* vat */}
                <div className="col-md-12 mt-2">
                  <Form.Group className="col-md-6 mt-2">
                    <Form.Label>{t("VAT")}</Form.Label>
                    <Form.Select
                      name="vat"
                      value={Vat || ""}
                      onBlur={() => setFieldTouched("vat", true)}
                      onChange={(e) => {
                        setFieldValue("vat", e.target.value);
                        setVAT(e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        {t("Select vat percentage")}
                      </option>
                      <option value="0">{t("VAT 0%")}</option>
                      <option value="6">{t("VAT low 6%")}</option>
                      <option value="9">{t("VAT low 9%")}</option>
                      <option value="21">{t("VAT high 21%")}</option>
                      <option value="1">{t("VAT shifted 0%")}</option>
                      <option value="2">{t("VAT exempt 0%")}</option>

                    </Form.Select>
                    {errors.vat && touched.vat ? (
                      <span className="error-message">{t(errors.vat)}</span>
                    ) : null}
                  </Form.Group>
                </div>

                <div className="row my-2 mt-3">
                  <div className="col-md-4">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {t("Save Product")}
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Form>
          )}
        </Formik>
      </Card>
    </>
  );
}

export default CreateOrEditProduct;
