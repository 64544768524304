import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, ButtonGroup, Card, Dropdown, Table } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Product from "../libs/Products";
import { Link } from "react-router-dom";
import { faEdit, faEllipsisH, faHome, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import AlertDismissible from "../../components/old/AlertDismissible";
import CustomSpinner from "../../components/CustomSpinner";
import { OldPagination } from "../../components/old/OldPagination";
import { numberFormatter } from "../../libs/numberFormatter";
import { useSelector } from "react-redux";
import { selectActiveCompanyId } from "../../store/reducers/userCompany";
import { extractPaginationData } from "../../libs/pagination";
import { Pagination } from "../../components/Pagination";
import ProductTableRow from "./ProductTableRow";

function Products() {
  const { t } = useTranslation();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [errors, setErrors] = useState([]);

  const companyId = useSelector(selectActiveCompanyId);

  const [paginationData, setPaginationData] = useState({
    links: [],
    prevPageUrl: "",
    nextPageUrl: "",
    showingPerPage: "",
    total: ""
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    getPaginatedProducts(page);
  }, [page]);

  const getPaginatedProducts = (page = null) => {
    setLoading(true);
    Product.getPaginatedProducts(companyId, page).then(res => {
      setLoading(false);
      if (res.products) {
        setProducts(res.products.data);
        setPaginationData(extractPaginationData(res.products));
      } else {
        setMessage({ show: true, message: res.message, variant: "warning" });
      }
    })

  }

  const deleteProduct = (productId) => {
    Product.deleteCompanyProduct(companyId, productId)
      .then(res => {
        if (res.success === true) {
          setMessage({ show: true, message: res.message, variant: "success" });
          getPaginatedProducts();
        } else if (res.success === false) {
          setMessage({ show: true, message: res.message, variant: "danger" });
        }

      }).catch(error => console.log(error));
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>{t("products")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("products")}</h4>
          <p className="mb-0">{t("Showing Product of your company")}</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Link to="/create-product" className="btn btn-outline-primary btn-sm"><FontAwesomeIcon icon={faPlus}
              className="me-2" />{t("new")}
            </Link>
          </ButtonGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {message.show ? <AlertDismissible textMessage={message.message} variant={message.variant} /> : ""}
          {errors &&
            errors.map((message, index) => {
              return (
                <AlertDismissible key={index} textMessage={message.message} index={index} variant="danger" />
              )
            })
          }
        </div>
      </div>

      {loading ? <CustomSpinner /> : <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0 pb-5">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">#</th>
                <th className="border-bottom">{t("Title")}</th>
                <th className="border-bottom">{t("Product Code")}</th>
                <th className="border-bottom">{t("EAN")}</th>
                <th className="border-bottom"> {t("price")} </th>
                <th className="border-bottom">{t("VAT")}</th>
                <th className="border-bottom">{t("action")}</th>
              </tr>
            </thead>
            <tbody>
              {products && products.map((product, index) => <ProductTableRow
                key={`contract-${index}`}
                index={index} {...product}
                deleteProduct={deleteProduct}
              />)}
            </tbody>
          </Table>

          <Pagination page={page} setPage={setPage} {...paginationData} />
        </Card.Body>
      </Card>}

    </>
  );
}

export default Products;
