import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const PasswordProtection = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  useEffect(() => {
    const protectionUsername = process.env.REACT_APP_PROTECTION_USERNAME;
    const protectionPassword = process.env.REACT_APP_PROTECTION_PASSWORD;

    if (!protectionUsername || !protectionPassword) {
      setIsAuthenticated(true);
      return;
    }

    const hasSession = localStorage.getItem("protected-session");
    if (hasSession) {
      setIsAuthenticated(true);
    } else {
      setShowPrompt(true);
    }
  }, []);

  const handleSubmit = () => {
    const protectionUsername = process.env.REACT_APP_PROTECTION_USERNAME;
    const protectionPassword = process.env.REACT_APP_PROTECTION_PASSWORD;

    if (
      username === protectionUsername &&
      password === protectionPassword
    ) {
      localStorage.setItem("protected-session", "true");
      setIsAuthenticated(true);
      setShowPrompt(false);
      history.push(history.location.pathname);
    } else {
      alert("Invalid credentials");
    }
  };

  if (!isAuthenticated && showPrompt) {
    return (
      <div
        className="position-fixed top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center bg-dark bg-opacity-50"
        style={{ zIndex: 9999 }}
      >
        <div className="bg-white p-4 rounded shadow" style={{ maxWidth: "400px", width: "100%" }}>
          <h2 className="text-center mb-4">Protected Environment</h2>
          <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <input
                type="text"
                id="username"
                className="form-control"
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="form-control"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSubmit();
                }}
              />
            </div>
            <div className="d-grid">
              <button
                type="submit"
                className="btn btn-success"
                disabled={!username || !password} // Disable button if fields are empty
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return children;
};

export default PasswordProtection;