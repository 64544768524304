import React, { useEffect, useState } from "react";
import InvoiceLine from "./InvoiceLine";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { FieldArray, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import InvoiceLineHeadRow from "./InvoiceLineHeadRow";
import { selectActiveCompany } from "../../store/reducers/userCompany";
import { getProducts } from "../../store/actions/products";

function InvoiceForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const { setFieldValue, errors, touched, values, handleBlur, setFieldError } = useFormikContext();
  const activeCompany = useSelector(selectActiveCompany);

  useEffect(() => {
    dispatch(getProducts(activeCompany?.id));
    //eslint-disable-next-line
  }, [activeCompany?.id]);

  useEffect(() => {
    setShowDeleteButton(values?.invoice_lines?.length > 1)
  }, [values?.invoice_lines]);

  return (
    <Card className="mt-4">
      <Card.Header className="p-2">
        <h5>{t("Invoice lines")}</h5>
      </Card.Header>
      <Card.Body>
        <FieldArray name="invoice_lines">
          {({ remove, push, handleChange }) => (
            <>
              <Table striped hover responsive size="sm" borderless={true}>
                <thead>
                  <InvoiceLineHeadRow />
                </thead>
                <tbody>
                  {values?.invoice_lines?.map((invoice_line, index) => (
                    <InvoiceLine
                      key={`invoiceline-${index}`}
                      rowId={index}
                      productLineField={'invoice_lines'}
                      deleteInvoiceLine={remove}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      values={invoice_line}
                      handleBlur={handleBlur}
                      showDeleteButton={showDeleteButton}
                      setFieldError={setFieldError}
                    />
                  ))}
                </tbody>
              </Table>
              <div className=" col-12 d-flex justify-content-center align-items-center p-2">
                <Button
                  variant="outline-primary"
                  className="btn-sm"
                  onClick={() => push({
                    product: null,
                    quantity: 1,
                    price: 0,
                    vat_percentage: 0,
                    amountExclVat_field: null,
                  })}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </>
          )}
        </FieldArray>
      </Card.Body>
    </Card>
  );
}

export default InvoiceForm;
