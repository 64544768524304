import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Button, Form } from "@themesberg/react-bootstrap";
import { parseISO } from "date-fns";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Company from "../../companies/libs/Company";
import AlertDismissible from "../../components/old/AlertDismissible";
import { numberFormatter } from "../../libs/numberFormatter";
import PriceCard from "../../components/PriceCard";
import CustomerModal from "../../customers/components/CustomerModal";
import InvoiceForm from "../../invoices/components/InvoiceForm";
import InvoiceReminder from "../../invoices/components/InvoiceReminder";
import { clearContract, getContractById } from "../../store/actions/contracts";
import { fetchCompanyCustomers, getCustomerById } from "../../store/actions/customers";
import { stopLoading } from "../../store/actions/loading";
import { getProducts } from "../../store/actions/products";
import { contractSchema } from "../../validation";
import Contract from "../libs/Contract";
import ContractInformation from "./ContractInformation";
import "../style/loader.scss";
import Auth from "../../auth/libs/Auth";
import { selectCustomers, selectCustomersWithLabelAndValue } from "../../store/reducers/customers";
import Preloader from "../../admin/layout/components/Preloader";
import { activeCompanyFetched, selectActiveCompany, selectUserCompanies } from "../../store/reducers/userCompany";
import { selectContract } from "../../store/reducers/contracts";
import { messageShown } from "../../store/reducers/message";

function CreateContract() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  const activeCompany = useSelector(selectActiveCompany);
  const userCompanies = useSelector(selectUserCompanies);
  const customerList = useSelector(selectCustomers);
  const customers = useSelector(selectCustomersWithLabelAndValue);
  const contract = useSelector(selectContract);

  const [customerId, setCustomerId] = useState(null);
  const [defaultSetting, setDefaultSetting] = useState(true);
  const [firstReminder, setFirstReminder] = useState(false);
  const [secondReminder, setSecondReminder] = useState(false);
  const [disableReminderButton, setDisableReminderButton] = useState(false);
  const [message, setMessage] = useState({});
  const [error, setError] = useState([]);
  const [formattedTotalPriceExclVat, setFormattedTotalPriceExclVat] =
    useState("");
  const [formattedTotalVat, setFormattedTotalVat] = useState("");
  const [formattedTotalInclVat, setFormattedTotalInclVat] = useState("");
  const [showCustomerModal, setShowCustomer] = useState(false);

  const handleCloseCustomer = () => setShowCustomer(false);

  // get user data and active company
  useEffect(() => {
    Auth.getUserData()
      .then((res) => {
        dispatch(stopLoading());
        if (res?.companies?.length > 0) {
          dispatch(activeCompanyFetched(res.companies[0]));
        }
      })
      .catch();
  }, []);

  /* 
  get contract, company customers and products
  or clear contract
  */
  useEffect(() => {

    dispatch(clearContract());
    if (id) {
      dispatch(getContractById(id));
    }

    dispatch(fetchCompanyCustomers());
    dispatch(getProducts(activeCompany?.id));

    // eslint-disable-next-line
  }, [dispatch, activeCompany?.id]);

  // if edit contract and contract's company id doesnt belong to user's, the redirect back
  useEffect(() => {
    if (id && Object.entries(contract).length !== 0 && contract.id) {
      if (!userCompanies.find(__userCompany => __userCompany.id === contract.company_id)) {
        dispatch(messageShown({ message: 'You are not authorized to edit this contract.', variant: "danger" }))

        window.location.href = "/contracts";
      }
    }

    //eslint-disable-next-line
  }, [contract]);

  // if customer id is set, get customer details by id
  useEffect(() => {
    if (customerId) {
      dispatch(getCustomerById(customerId));
    }
    // eslint-disable-next-line
  }, [customerId, customerList]);

  // set default settings, customer id , first and second reminder based on contract
  useEffect(() => {
    if (id && Object.entries(contract).length !== 0) {
      if (contract?.first_reminder) {
        setDefaultSetting(false);
      }

      setCustomerId(contract.customer_id);
      setFirstReminder(contract?.first_reminder);
      setSecondReminder(contract?.second_reminder);
    }

    //eslint-disable-next-line
  }, [contract]);

  /* 
  if first reminder on - turn off disable second reminder
  if first reminder off - set second reminder off + disable second reminder 
  */
  useEffect(() => {
    if (firstReminder) {
      setDisableReminderButton(false);
    } else {
      setDisableReminderButton(true);
      setSecondReminder(false);
    }
  }, [firstReminder]);

  const storeContract = (values) => {

    if (id) {
      const newContract = {
        id: id,
        customer_id: values.customer_id,
        start_date: values.start_date
          ? moment(values.start_date).format("YYYY-MM-DD")
          : "",
        end_date: values.end_date
          ? moment(values.end_date).format("YYYY-MM-DD")
          : "",
        first_invoice_date: values.first_invoice_date
          ? moment(values.first_invoice_date).format("YYYY-MM-DD")
          : "",
        status: values.status,
        email: values.email,
        frequency: values.frequency,

        first_reminder: firstReminder,
        second_reminder: secondReminder,
        invoice_lines: values.invoice_lines,
        description: values.description,
      };

      Contract.updateContract(newContract)
        .then((res) => handleContractCreateOrUpdateResponse(res))
        .catch((error) => handleContractCreateOrUpdateError(error));
    } else {
      const formatDate = (date) => (date ? moment(date).format("DD-MM-YYYY") : "");

      const newContractData = {
        ...values,
        start_date: formatDate(values.start_date),
        end_date: formatDate(values.end_date),
        first_invoice_date: formatDate(values.first_invoice_date),
        first_reminder: firstReminder,
        second_reminder: secondReminder,
      };

      Contract.storeContract(newContractData)
        .then((res) => handleContractCreateOrUpdateResponse(res))
        .catch((error) => handleContractCreateOrUpdateError(error));
    }
  };

  function handleContractCreateOrUpdateResponse(res) {

    if (res.errors) {
      let array = [];
      for (const key in res.errors) {
        array.push({
          show: true,
          message: res.errors[key][0],
          variant: "danger",
        });
      }
      setError(array);
    }

    dispatch(stopLoading());

    if (res.success === true) {
      setMessage({
        show: true,
        message: res.message,
        variant: "success",
      });

      dispatch(messageShown({ message: res.message, variant: "success" }))

      window.location.href = "/contracts";

    } else if (res.success === false) {
      setMessage({ show: true, message: res.message, variant: "danger" });
    }
  }

  function handleContractCreateOrUpdateError(error) {
    setMessage({ show: true, message: 'Something went wrong. Please try again.', variant: "danger" })
    console.log(error)
  }

  const handleDefaultSetting = () => {
    if (defaultSetting) {
      setDefaultSetting(false);
    } else {
      setDefaultSetting(true);
    }
    setFirstReminder(false);
    setSecondReminder(false);
  };

  const handleSaveCustomer = (customer) => {
    dispatch(fetchCompanyCustomers());
    handleCloseCustomer();
  };

  const setAndReturnFormattedPrice = (invoiceLines, calculationType) => {
    let price = [];
    let totalVat = 0;
    if (invoiceLines) {
      for (let i = 0; i < invoiceLines?.length; i++) {
        price.push(invoiceLines[i]?.amountExclVat_field);
        totalVat =
          totalVat +
          (invoiceLines[i]?.amountExclVat_field / 100) * invoiceLines[i]?.vat_percentage;
      }
    }
    let amountExclVat = price.reduce((sum, newValue) => sum + newValue, 0);
    setTimeout(() => {
      setFormattedTotalPriceExclVat(amountExclVat);
      setFormattedTotalVat(totalVat);
      setFormattedTotalInclVat(amountExclVat + totalVat);
    }, 0);

    if (calculationType === 1) {
      return formattedTotalPriceExclVat;
    } else if (calculationType === 2) {
      return formattedTotalVat;
    } else if (calculationType === 3) {
      return formattedTotalInclVat;
    }
  };

  const contractInvoiceLines = contract?.contract_invoice_lines || [
    {
      product: null,
      description: "",
      quantity: 1,
      price: 0,
      price_formatted: 0,
      vat_percentage: 0,
      amountExclVat_field: 0,
    },
  ];

  const initialcontractInvoiceLines = !id ? contractInvoiceLines : contractInvoiceLines.map(
    (invoiceLine) => ({
      product_id: invoiceLine.product_id,
      product: {
        id: invoiceLine.product_id,
        label: invoiceLine.description,
        price: invoiceLine.product_id ? invoiceLine.price : null,
        vat: invoiceLine.product_id ? invoiceLine.vat_percentage : null
      },
      description: invoiceLine.description,
      quantity: invoiceLine.quantity,
      price: invoiceLine.price,
      price_formatted: numberFormatter.format(invoiceLine.price / 100),
      vat_percentage: invoiceLine.vat_percentage,
      amountExclVat_field: invoiceLine.price / 100,
    })
  );

  const initialValues = {
    id: id,
    customer_id: contract?.customer_id,
    start_date: contract?.start_date && parseISO(contract?.start_date),
    first_invoice_date:
      contract?.first_invoice_date && parseISO(contract?.first_invoice_date),
    status: contract?.status,
    email: contract?.email,
    frequency: contract?.frequency,
    end_date: contract?.end_date && parseISO(contract?.end_date),
    invoice_lines: initialcontractInvoiceLines,
    description: contract?.description,
    company_id: activeCompany?.id,
    contact_person_id: contract?.contact_person_id,
  };

  // console.log(moment.tz(parseISO(contract?.start_date), "Europe/Amsterdam").format("YYYY-MM-DD"), "parseISO(contract?.start_date)parseISO(contract?.start_date)parseISO(contract?.start_date)");

  return (
    <>
      {!activeCompany?.id && <Preloader />}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/contracts">
              {t("contracts")}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {id ? t("edit") : t("Create")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <h4>{id ? t("Edit contract") : t("Create new contract")}</h4>
          <p className="mb-0">
            {id
              ? t("Edit an existing contract")
              : t("create a new contract for your company")}
          </p>
        </div>
      </div>

      <div className="row">
        {message.show ? (
          <AlertDismissible
            textMessage={message.message}
            variant={message.variant}
          />
        ) : (
          ""
        )}
        {error &&
          error.map((message, index) => {
            return (
              <AlertDismissible
                key={index}
                textMessage={message.message}
                variant="danger"
              />
            );
          })}
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            storeContract(values);
          }, 1000);
        }}
        validationSchema={contractSchema}
      >
        {({
          errors,
          touched,
          handleSubmit,
          handleBlur,
          values,
          setFieldValue,
          isSubmitting,
          handleChange
        }) => (
          <Form onSubmit={handleSubmit}>

            <ContractInformation
              setFieldValue={setFieldValue}
              customers={customers}
              setMessage={setMessage}
              customerId={customerId}
              setCustomerId={setCustomerId}
              handleBlur={handleBlur}
              errors={errors}
              values={values}
              touched={touched}
              setShowCustomer={setShowCustomer}
            />

            <InvoiceForm />

            <PriceCard
              cardType="contract"
            />

            <InvoiceReminder
              setFirstReminder={setFirstReminder}
              defaultSetting={defaultSetting}
              disableReminderButton={disableReminderButton}
              firstReminder={firstReminder}
              handleDefaultSetting={handleDefaultSetting}
              secondReminder={secondReminder}
              setSecondReminder={setSecondReminder}
            />

            <div className="row my-3 mx-0">
              <div className="col-md-4 mb-3">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {id ? t("Update Contract") : t("Save Contract")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <CustomerModal
        show={showCustomerModal}
        handleClose={handleCloseCustomer}
        handleSave={handleSaveCustomer}
      />
    </>
  );
}

export default CreateContract;
